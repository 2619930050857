import { lazy, Suspense } from "react";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Loader from "./components/Loader";
//import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

//import DesenvolvimentoWebArticle from "./components/artigos/DesenvolvimentoWebArticle";
//import WebDesignArticle from "./components/artigos/WebDesignArticle";
//import SegmentosDesenvolvimentoWebArticle from "./components/artigos/SegmentosDesenvolvimentoWebArticle";
//import BibliotecasFrameworkArticle from "./components/artigos/BibliotecasFrameworkArticle";
//import APIsArticle from "./components/artigos/APIsArticle";
//import BancoDeDadosArticle from "./components/artigos/BancoDeDadosArticle";

//import PoliticaPrivacidade from "./components/paginas/PoliticaPrivacidade";
//import CssArticle from "./components/CssArticle";
//import HtmlArticle from "./components/HtmlArticle";
//import ReactArticle from "./components/ReactArticle";

const App = lazy(() => import("./App"));

const CssArticle = lazy(() => import("./components/artigos/CssArticle"));
const HtmlArticle = lazy(() => import("./components/artigos//HtmlArticle"));
const ReactArticle = lazy(() => import("./components/artigos//ReactArticle"));

const DesenvolvimentoWebArticle = lazy(() =>
  import("./components/artigos/DesenvolvimentoWebArticle")
);

const WebDesignArticle = lazy(() =>
  import("./components/artigos/WebDesignArticle")
);

const SegmentosDesenvolvimentoWebArticle = lazy(() =>
  import("./components/artigos/SegmentosDesenvolvimentoWebArticle")
);

const BibliotecasFrameworkArticle = lazy(() =>
  import("./components/artigos/BibliotecasFrameworkArticle")
);

const APIsArticle = lazy(() => import("./components/artigos/APIsArticle"));

const BancoDeDadosArticle = lazy(() =>
  import("./components/artigos/BancoDeDadosArticle")
);

const TermosUso = lazy(() => import("./components/paginas/TermosUso"));

const PoliticaPrivacidade = lazy(() =>
  import("./components/paginas/PoliticaPrivacidade")
);

const ProgramacaoArticle = lazy(() =>
  import("./components/artigos/ProgramacaoArticle")
);

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={<Loader />}>
        <App />
      </Suspense>
    ),
  },

  {
    path: "/react",
    element: (
      <Suspense fallback={<Loader />}>
        <ReactArticle />
      </Suspense>
    ),
  },
  {
    path: "/css",
    element: (
      <Suspense fallback={<Loader />}>
        <CssArticle />
      </Suspense>
    ),
  },
  {
    path: "/html",
    element: (
      <Suspense fallback={<Loader />}>
        <HtmlArticle />
      </Suspense>
    ),
  },
  {
    path: "/uma-introducao-ao-desenvolvimento-web",
    element: (
      <Suspense fallback={<Loader />}>
        <DesenvolvimentoWebArticle />
      </Suspense>
    ),
  },
  {
    path: "/web-design-o-elemento-central-da-construcao-de-paginas",
    element: (
      <Suspense fallback={<Loader />}>
        <WebDesignArticle />
      </Suspense>
    ),
  },
  {
    path: "/os-segmentos-da-area-do-desenvolvimento-de-aplicacoes",
    element: (
      <Suspense fallback={<Loader />}>
        <SegmentosDesenvolvimentoWebArticle />
      </Suspense>
    ),
  },
  {
    path: "/definicoes-e-conceitos-de-bibliotecas-e-frameworks",
    element: (
      <Suspense fallback={<Loader />}>
        <BibliotecasFrameworkArticle />
      </Suspense>
    ),
  },
  {
    path: "/apis-definicao-e-funcoes",
    element: (
      <Suspense fallback={<Loader />}>
        <APIsArticle />
      </Suspense>
    ),
  },
  {
    path: "/banco-de-dados-no-desenvolvimento-de-aplicacoes",
    element: (
      <Suspense fallback={<Loader />}>
        <BancoDeDadosArticle />
      </Suspense>
    ),
  },
  {
    path: "/politica-de-privacidade",
    element: (
      <Suspense fallback={<Loader />}>
        <PoliticaPrivacidade />
      </Suspense>
    ),
  },
  {
    path: "/termos-de-uso",
    element: (
      <Suspense fallback={<Loader />}>
        <TermosUso />
      </Suspense>
    ),
  },
  {
    path: "/o-primeiro-passo-para-se-tornar-um-programador",
    element: (
      <Suspense fallback={<Loader />}>
        <ProgramacaoArticle />
      </Suspense>
    ),
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

/*
,
  {
    path: "/react",
    element: (
      <Suspense fallback={<span>Loading...</span>}>
        <ReactArticle />
      </Suspense>
    ),
  },
  {
    path: "/css",
    element: (
      <Suspense fallback={<span>Loading...</span>}>
        <CssArticle />
      </Suspense>
    ),
  },
  {
    path: "/html",
    element: (
      <Suspense fallback={<span>Loading...</span>}>
        <HtmlArticle />
      </Suspense>
    ),
  },
*/
